<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<div class="page-desc">
			该报表统计成本计算方法为批量管理的耗材，当月到保质期以及已经过期的耗材
		</div>  
			<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<div  style="position: absolute;right:20px;z-index: 999;">
					<el-button  type="primary" @click="handleExport">导出全部</el-button> 
				</div>
			    <el-form-item label="">
			        <el-input v-model="search_key.keyword" placeholder="请输入派发单号搜索" style="width:200px;"></el-input>
			    </el-form-item>
			    <el-button type="primary" @click="search()">查询</el-button>
			</el-form>
			
		<el-table :data="tableData" border >
			<el-table-column prop="mtl_code" label="物料编码">
			</el-table-column>
			<el-table-column prop="name" label="物料名称">
			</el-table-column>
			<el-table-column prop="exp_date" label="最后有效期">
			</el-table-column>
			<el-table-column prop="wty_period"label="保质期">
			</el-table-column>
			<el-table-column prop="prod_date" label="生产日期">
			</el-table-column>
			<el-table-column prop="cate_name" label="物料分类">
			</el-table-column>
			<el-table-column prop="batch_number" label="批次号">
			</el-table-column>
			<el-table-column prop="bar_code" label="商品条码">
			</el-table-column>
		 </el-table>
		 <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 

	
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				changeStatus:false,
				active: 1,
				tableData: [],
				currentPage:1,
				pagesize:10,
				total:0,
				search_key:{
					keyword:''
				}
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData() {
				this.$api.post('CseReport/expired', {
					page:this.currentPage-1,
					limit:this.pagesize,
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.changeStatus = !this.changeStatus
						this.tableData = res.data.list
						this.total =  res.data.count		
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			search(){
				this.currentPage  = 1
				this.getData()
			},
			getCurrPage(val){   //获取当前页
			    this.currentPage =  val
			    this.getData()
			},
			getPageSize(val){   //获取每页条数
			    this.pagesize =  val
			     this.getData()
			},	
			
			handleExport(){
				location.href = '/api/admin/CseReport/exportExpired'
			}
		},
	}
</script>
<style scoped>

</style>
